const primary = '#002256ff';
const secondary = '#d9a73c';
const bgColor = '#ffffff';

const baseColors = {
  primary,
  secondary,
  red: '#dc3545',
  primaryBg: bgColor,

  // datatable
  bgDatatable: bgColor,
  colorDatatable: 'null',

  // select
  bgSelect: bgColor,
  bgMultiValueSelect: secondary,
  hoverBgMultiValueRemoveSelect: secondary,
  colorMultiValueSelect: primary,
  hoverTextMultiValueRemoveSelect: bgColor,

  // text points product
  colorTextPointsProducts: primary,

  // login logo size
  logoHeight: 340,
  columnMd: 3,
  classLogo: 'logo-xl',

  // text color login (className)
  textColorLogin: 'text-white',
  classNameBtnLogin: 'bg-red',

  // home style (className)
  boxColorMyPoints: 'bg-color-my-points',

  // profile style (className)
  bgColorProfile: 'bg-white',

  gray100: '#f8f9fa',
  gray200: '#e9ecef',
  gray300: '#dee2e6',
  gray400: '#ced4da',
  gray500: '#adb5bd',
  gray600: '#6c757d',
  gray700: '#495057',
  gray800: '#343a40',
  gray900: '#282828'
};

export default baseColors;
